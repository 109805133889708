import { isBrowser } from './is-browser'

declare global {
  interface Window {
    env: {
      SUPABASE_STORAGE: string
      LOGROCKET: string
      SENTRY_ENABLED: string
      SENTRY_DSN: string
      MAINTENANCE_MODE: string
      MAINTENANCE_MODE_MESSAGE: string
      IMPERSONATE_USERS: string
    }
  }
}

declare global {
  namespace NodeJS {
    interface ProcessEnv {
      SUPABASE_STORAGE: string
      SESSION_SECRET: string
      LOGROCKET: string
      CHATBOT_API: string
      AUTH0_SECRET: string
      AUTH0_DOMAIN: string
      AUTH0_CLIENT_ID: string
      AUTH0_MANAGEMENT_ID: string
      AUTH0_MANAGEMENT_SECRET: string
      SENTRY_ENABLED: string
      SENTRY_DSN: string
      MAINTENANCE_MODE: string
      MAINTENANCE_MODE_MESSAGE: string
      VERCEL_ENV: string
      IMPERSONATE_USERS: string
      RESEND_API_KEY: string
      EMAILS_NO_REPLY: string
      EMAILS_BCC: string
    }
  }
}

type EnvOptions = {
  isSecret?: boolean
  isRequired?: boolean
}
function getEnv(
  name: string,
  { isRequired, isSecret }: EnvOptions = { isSecret: true, isRequired: true },
) {
  if (isBrowser && isSecret) return ''

  const source = (isBrowser ? window.env : process.env) ?? {}

  const value = source[name as keyof typeof source]

  if (!value && isRequired) {
    throw new Error(`${name} is not set`)
  }

  return value
}

/**
 * Server env
 */
export const SESSION_SECRET = getEnv('SESSION_SECRET')
export const AUTH0_SECRET = getEnv('AUTH0_SECRET')
export const AUTH0_DOMAIN = getEnv('AUTH0_DOMAIN')
export const AUTH0_CLIENT_ID = getEnv('AUTH0_CLIENT_ID')
export const VERCEL_ENV = getEnv('VERCEL_ENV', { isRequired: false })
export const RESEND_API_KEY = getEnv('RESEND_API_KEY', { isRequired: false })
export const EMAILS_NO_REPLY = getEnv('EMAILS_NO_REPLY', { isRequired: false })
export const EMAILS_BCC = getEnv('EMAILS_BCC', { isRequired: false })

/**
 * Shared envs
 */
export const NODE_ENV = getEnv('NODE_ENV', {
  isSecret: false,
  isRequired: false,
})
export const CHATBOT_API = getEnv('CHATBOT_API', { isSecret: false })
export const SUPABASE_STORAGE = getEnv('SUPABASE_STORAGE', { isSecret: false })
export const SENTRY_DSN = getEnv('SENTRY_DSN', { isSecret: false })
export const SENTRY_ENABLED = getEnv('SENTRY_ENABLED', { isSecret: false })
export const LOGROCKET = getEnv('LOGROCKET', { isSecret: false })
export const MAINTENANCE_MODE = getEnv('MAINTENANCE_MODE', { isSecret: false })
export const MAINTENANCE_MODE_MESSAGE = getEnv('MAINTENANCE_MODE_MESSAGE', {
  isSecret: false,
})
export const IMPERSONATE_USERS = getEnv('IMPERSONATE_USERS', {
  isSecret: false,
})

export function getBrowserEnv() {
  return {
    SUPABASE_STORAGE,
    SENTRY_DSN,
    SENTRY_ENABLED,
    LOGROCKET,
    MAINTENANCE_MODE,
    MAINTENANCE_MODE_MESSAGE,
    IMPERSONATE_USERS,
  }
}

export function isDev() {
  return process.env.NODE_ENV === 'development'
}
export function isStaging() {
  return isDev() ? process.env.DATABASE_URL?.includes('staging') : VERCEL_ENV !== 'production'
}
